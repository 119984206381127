export const slugify = (str) =>
  str
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "")

export const slugifyCollection = (str) =>
  encodeURIComponent(base64Encode(str.toLowerCase()))

const base64Encode = (str) => {
  const bytes = new TextEncoder().encode(str)
  const binString = Array.from(bytes, (byte) =>
    String.fromCodePoint(byte),
  ).join("")
  return btoa(binString)
}
