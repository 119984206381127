import Link from "next/link"
import React from "react"
import { imageResizer } from "../../../services/utils/imageResizer"
import styles from "./LikeCards.module.css"
import classnames from "classnames"

const LikeCards = ({
  title,
  subtitle,
  subSubTitle,
  image,
  to,
  href,
  type = "small",
}) => {
  const Card = () => (
    <div className="mb-5">
      <div
        className={classnames(styles["like-cards"], "like-cards", styles[type])}
        style={{
          backgroundImage: `url(${imageResizer(
            image,
            type === "small" ? 600 : "AUTO",
            type === "small" ? "AUTO" : 900,
          )})`,
        }}
      />
      {title && (
        <h4 className="text-black mb-0 font-secondary fw-700 fs-16px elipsis-2-lines">
          {title}
        </h4>
      )}

      {subtitle && (
        <p className="mb-0 font-primary text-black fs-14px font-primary">
          {subtitle}
        </p>
      )}

      {subSubTitle && (
        <p className="mb-0 color-gray-super-light fs-13px font-primary fs-13px">
          {subSubTitle}
        </p>
      )}
    </div>
  )

  if (to && href) {
    return (
      <Link href={href} as={to}>
        <Card />
      </Link>
    )
  }

  return <Card />
}

export default LikeCards
